//import * as React from "react";
import React, { useState } from "react";
import {
    TextInput,
    SimpleForm,
    Edit,
    SaveButton,
    Toolbar,
    SelectInput,
    DateInput,
    useNotify,
    useRefresh
} from 'react-admin';
import { Typography, Grid, Button } from '@material-ui/core';
import { makeStyles, darken, fade, lighten } from '@material-ui/core/styles';
import { ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import JWT from "jsonwebtoken";
import api_url from './ApiUrl';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';


const newStyle = makeStyles(theme => ({
    inputHeight: { height: '50px !important' },
    fontBold: { fontWeight: '400', textTransform: 'capitalize', fontSize: '15px', color: '#80a3b6' },
    marginTop15: { marginTop: '15px', marginBottom: '15px' },
    marginTop10: { marginTop: '10px' },
    marginTop20: { marginTop: '20px' },
    marginBottom20: { marginBottom: '20px' },
    paddingLeft15: { paddingLeft: '15px' },
    paddingRight15: { paddingRight: '15px' },
    rightAlignedCell: { textAlign: 'right' },
    marginBottom10: { marginBottom: '10px' },
    borderbottom0: { borderBottom: '0', textAlign: 'right' },
    statusIconSet: { display: 'flex', position: 'absolute', height: '18px', right: '375px' },
    hrline: { borderBottom: '1px solid rgba(128, 163, 182, 0.1)', marginBottom: '15px' },
    headerText: { fontSize: '0.875rem', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 500, marginBottom: '0' },
    headerText2: { fontSize: '0.875rem', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 500, marginBottom: '0', textAlign: 'right' },
    generalText: { fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 400 },
    generalText2: { fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 400, textAlign: 'right' },
    generalText3: { fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 500, textAlign: 'right', borderBottom: '0' },
    generalText4: { fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 500, textAlign: 'right', borderBottom: '0', textTransform: 'capitalize' },
    root: {
        width: '96%',
        margin: '0 auto',
        borderStyle: 'solid',
        borderWidth: 1,
        marginTop: '2rem',
        marginBottom: '2rem',
        borderColor:
            theme.palette.type === 'light'
                ? lighten(fade(theme.palette.divider, 1), 0.88)
                : darken(fade(theme.palette.divider, 1), 0.68),
    },
    spacer: { height: 20 },
    invoices: { margin: '10px 0' },
    invoiceNumberBody: theme.palette.type === 'light' ? { backgroundColor: 'rgb(245 245 245)' } : { backgroundColor: 'rgb(83,83,83)' },
    paynowButton: theme.palette.type === 'light' ? { backgroundColor: '#4f3cc9', color: '#fff', '&:hover': { backgroundColor: 'rgb(55, 42, 140)' } } : { backgroundColor: '#90caf9', color: 'rgba(0, 0, 0, 0.87)', '&:hover': { backgroundColor: 'rgb(100, 141, 174)' } },
}));

const CustomToolbar = (props) => {
    const classes = newStyle();
    const o_status = props.record.status;
    const orderProcessBy = props.record.order_mode;
    const order_id = props.record.id;
    const [payStatus, setPayStatus] = useState();
    const [payMessage, setPayMessage] = useState();
    const [isdiabled, setIsdiabled] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();

    const handlePayNowAction = async (order_id) => {
        setIsdiabled(true);
        let login_token = localStorage.getItem('auth');
        let apitoken = JWT.sign({ url: api_url() + '/paymanually' }, "eyJhbGciOJIUzI1NiIsInR5cCI6IkpXVCJ9eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2Mj5MDIyfQSflKxwRJSMeKF2QT4fwpMeJf36POk6yJVadQsw5c", {
            algorithm: "HS512",
        });
        const request = new Request(api_url() + '/paymanually', {
            method: 'POST',
            body: JSON.stringify({ 'id': order_id }),
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${login_token}`,
                'xelxtoken': apitoken
            }),
        });
        const response = await fetch(request);
        const jsonData = await response.json();
        notify(`${jsonData.message}`, { type: 'success' });
        refresh();
        setIsdiabled(false);
    };

    // const handleClick = () => {
    //     this.props.goBack();
    // };

    return (
        <Toolbar {...props} style={{ textAlign: 'right' }} className={classes.invoiceNumberBody}>
            {(o_status == 'waiting' || o_status == 'processing' || o_status == 'hold') ? ((o_status == 'processing' && orderProcessBy == 'manual') ?
                (<SaveButton
                    label="Save"
                    redirect="show"
                    submitOnEnter={true}
                    style={{ marginRight: '20px' }}
                    disabled={props.pristine}
                />) : ((o_status == 'processing' && orderProcessBy == 'semiautomatic') ?
                    ((props.currentbutton == 'save') ? <SaveButton
                        label="Save"
                        redirect="show"
                        submitOnEnter={true}
                        style={{ marginRight: '20px' }}
                        disabled={props.pristine}
                    /> : <Button variant="outlined" color="default" disabled={isdiabled} onClick={(e) => handlePayNowAction(order_id)} style={{ marginRight: '20px' }} className={classes.paynowButton}><ReceiptOutlinedIcon fontSize="small" style={{ height: '15px', paddingRight: '8px' }} /> Pay Now</Button>)
                    : ((o_status == 'processing' && orderProcessBy == 'automatic') ?
                        (<SaveButton
                            label="Save"
                            redirect="show"
                            submitOnEnter={true}
                            style={{ marginRight: '20px' }}
                            disabled={props.pristine}
                        />) : (<SaveButton
                            label="Save"
                            redirect="show"
                            submitOnEnter={true}
                            style={{ marginRight: '20px' }}
                            disabled={props.pristine}
                        />)))) : ('')}


            {/* <Link style={{ textAlign: 'right' }} to={'/orders?filter={"type":"' + o_status + '"}'}><Button variant="outlined" color="default">Back</Button></Link> */}
            <Button variant="outlined" color="default">Back</Button>

        </Toolbar>
    )
};

const OrderShowActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={false} />
)



const OrderDetailsForm = (props) => {
    const fromCoinString = props.record.from_coin_string;
    const toCoinString = props.record.to_coin_string;
    const ownerAddress = props.record.owner_address;
    const customerAddress = props.record.customer_address;
    const customerName = props.record.customer_name;
    const orderStatus = props.record.status;
    const orderMode = props.record?.order_mode;
    const totalReceivaleAmount = props.record.received_amount;
    const invoiceNumber = props.record.transaction_id;
    const createDate = props.record.created_at;
    const expiredDate = (orderStatus == 'expired') ? props.record.order_expire_time : props.record.status_change_time; //status_change_time
    const orderDescription = props.record.order_description;
    const transaction_number_admin = props.record.transaction_number_admin;
    const transaction_number = props.record.transaction_number;
    const processingFee = props.record.processing_fee;
    const processingFeeType = (props.record.processing_fee_type == 'Fixed') ? '' : '%';
    const to_coin = props.record.to_coin;
    const from_coin = props.record.from_coin;
    const orderQty = props.record.send_amount;
    const orderPrice = props.record.course_price_alter;
    const orderTotal = props.record.received_amount_alter;
    const slipageAmount = props.record.slipage_amount;
    const feeText = props.record.fee_text;
    const feeAmount = props.record.fee_amount;
    const orginalFee = props.record.orginal_fee;
    const orginalFeeTotal = props.record.orginal_fee_total;
    const subTotal = props.record.subtotal;
    const netTotal = props.record.nettotal;
    const toCoinFull = props.record.to_coin_full;
    const fromCoinFull = props.record.from_coin_full;
    const bonusExchangeText = props.record.bonus_exchange_text;
    const bonus = props.record.bonus;
    const bonusExchange = props.record.bonus_exchange;
    const toCoin = props.record.to_coin;

    const classes = newStyle();
    const [currentButton, setCurrentButton] = useState(((props.record.status == 'processing') && (props.record.order_mode == 'semiautomatic')) ? 'paynow' : 'save');
    //console.log('===' + currentButton);

    const pairtype_choices = (orderStatus == 'hold') ? ([
        { id: 'hold', name: 'Hold' },
        { id: 'processing', name: 'Processing' },
        { id: 'refund', name: 'Refund' }]) : ((orderStatus == 'processing' && (orderMode == 'semiautomatic' || orderMode == 'automatic')) ? ([
            { id: 'processing', name: 'Processing' },
            { id: 'hold', name: 'Hold' }
        ]) : ((orderMode == 'manual' && orderStatus == 'processing') ? ([
            { id: 'processing', name: 'Processing' },
            { id: 'completed', name: 'Completed' },
            { id: 'hold', name: 'Hold' },
        ]) : ((orderStatus == 'waiting') ? [
            { id: 'waiting', name: 'Waiting' },
            { id: 'processing', name: 'Processing' },
            { id: 'expired', name: 'Expired' },
            { id: 'hold', name: 'Hold' },
        ] : [
            { id: 'expired', name: 'Expired' },
            { id: 'waiting', name: 'Waiting' },
            { id: 'processing', name: 'Processing' },
            { id: 'completed', name: 'Completed' },
            { id: 'hold', name: 'Hold' },
            { id: 'refund', name: 'Refund' }])));

    const handleOrderStatus = (event) => {
        //console.log(event.target.value);
        if (event.target.value == 'processing' && props.record.order_mode == 'semiautomatic') {
            setCurrentButton('paynow');
        }
        if (event.target.value == 'hold' && props.record.order_mode == 'semiautomatic') {
            setCurrentButton('save');
        }
    }

    return (
        <SimpleForm {...props} toolbar={<CustomToolbar currentbutton={currentButton} />} className="orderDetails">
            {(orderStatus == 'expired' || orderStatus == 'completed' || orderStatus == 'refund') ? <>
                <Grid container className={classes.marginBottom20} style={{ padding: '20px' }}>
                    <Grid item xs={6}>
                        <img src="images/logoshort.svg" height="35" />
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right', textTransform: 'capitalize' }}>
                        {(orderStatus == 'completed') ?
                            <Typography variant="span" style={{ backgroundColor: 'rgba(84, 214, 44, 0.16)', borderRadius: '5px', padding: '3px 7px', color: '#229A16', textTransform: 'uppercase', fontWeight: '600', fontSize: '0.75rem' }}>
                                Completed
                            </Typography> :
                            (orderStatus == 'expired') ?
                                <Typography variant="span" style={{ backgroundColor: 'rgba(255, 72, 66, 0.16)', fontWeight: '600', borderRadius: '5px', padding: '3px 7px', color: 'rgb(183, 33, 54)', textTransform: 'uppercase', fontSize: '0.75rem' }}>
                                    Expired
                                </Typography> :
                                ((orderStatus == 'refund') ?
                                    <Typography variant="span" style={{ backgroundColor: 'rgba(51, 102, 255, 0.16)', borderRadius: '5px', padding: '3px 7px', color: '#1939B7', textTransform: 'uppercase', fontWeight: '600', fontSize: '0.75rem' }}>
                                        REFUNDED
                                    </Typography> :
                                    <Typography variant="span" style={{ backgroundColor: 'rgb(220 0 78 / 27%)', borderRadius: '5px', padding: '3px 7px', color: 'rgb(183, 33, 54)', textTransform: 'uppercase', fontWeight: '600', fontSize: '0.75rem' }}>
                                        {(orderStatus == 'expired') ? 'EXPIRED' : ((orderStatus == 'refund') ? 'REFUNDED' : orderStatus)}
                                    </Typography>)}
                        <Typography style={{ marginTop: '10px', fontWeight: '600' }}>{invoiceNumber}</Typography>
                    </Grid>
                </Grid>
            </> : ''}
            <Grid container className={classes.marginBottom20} style={{ padding: '20px', fontSize: '0.875rem !important' }}>
                <Grid item xs={6} style={{ borderRight: (orderStatus == 'expired' || orderStatus == 'completed' || orderStatus == 'refund') ? '' : '1px dashed #ccc' }}>
                    <Typography variant="body1" style={{ fontSize: '0.875rem', fontWeight: '600' }}>INVOICE FROM :</Typography>
                    <Typography variant="body1">
                        Libra Exchange (SV) LLC<br />
                        {fromCoinString}<br />
                        {ownerAddress}
                    </Typography>

                    {(orderStatus == 'expired' || orderStatus == 'completed' || orderStatus == 'refund') ? <>
                        <Typography variant="body2" style={{ marginTop: '30px', fontWeight: '600' }}>DATE CREATED</Typography>
                        <Typography variant="body2" style={{ marginBottom: '15px' }}>{createDate}</Typography>
                    </> : ''}
                </Grid>
                <Grid item xs={6} className={classes.paddingLeft15}>
                    <Typography variant="body2" style={{ fontWeight: '600' }}>INVOICE TO:</Typography>
                    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: customerName }}></Typography>
                    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: toCoinString }}></Typography>
                    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: customerAddress }}></Typography>
                       
                    {((orderStatus == 'expired' || orderStatus == 'completed' || orderStatus == 'refund') && (expiredDate != '')) ? <>
                        <Typography variant="body2" style={{ marginTop: '30px', fontWeight: '600', textTransform: 'uppercase' }}>DATE {(orderStatus == 'expired') ? 'expired' : orderStatus}</Typography>
                        <Typography variant="body2" style={{ marginBottom: '15px' }}>{expiredDate}</Typography>
                    </> : ''}
                </Grid>
            </Grid>

            {(orderStatus == 'expired' || orderStatus == 'completed' || orderStatus == 'refund') ? <>

                <Grid container>
                    <Grid item xs={1} className={classes.paddingLeft15}>
                        <Typography variant="body1">#</Typography>
                    </Grid>
                    <Grid item xs={5} className={classes.paddingLeft15}>
                        <Typography variant="body1" style={{ fontWeight: '600', marginLeft: '-25px' }}>Description</Typography>
                    </Grid>

                    <Grid item xs={2} className={classes.paddingLeft15}>
                        <Typography variant="body1" style={{ fontWeight: '600' }}>Qty</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.paddingLeft15}>
                        <Typography variant="body1" style={{ fontWeight: '600' }}>Price</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.paddingLeft15} style={{ paddingRight: '15px', textAlign: 'right' }}>
                        <Typography variant="body1" style={{ fontWeight: '600', textAlign: 'right' }}>Total</Typography>
                    </Grid>
                </Grid>
                <Grid container style={{ margin: '10px 15px' }}>
                    <Typography variant="body1" style={{ height: '1px', borderBottom: '1px dashed #ccc', width: '98%' }}></Typography>
                </Grid>
                <Grid container>
                    <Grid item xs={1} className={classes.paddingLeft15}>
                        <Typography variant="body1">1</Typography>
                    </Grid>
                    <Grid item xs={5} className={classes.paddingLeft15}>
                        <Typography variant="body1" style={{ marginLeft: '-25px', fontWeight: '600' }}>{orderDescription}<br />
                            {(to_coin == 'BTC') ?
                                <a href={`https://blockchair.com/bitcoin/transaction/${transaction_number_admin}`} target="_blank"><span style={{ fontSize: '0.75rem', fontWeight: '400' }}>{transaction_number_admin}</span></a>
                                : ((toCoinFull == 'USDTERC20' || toCoinFull == 'USDCERC20' || to_coin == 'ETH') ?
                                    <a href={`https://etherscan.io/tx/${transaction_number_admin}`} target="_blank"><span style={{ fontSize: '0.75rem', fontWeight: '400' }}>{transaction_number_admin}</span></a>
                                    : ((toCoinFull == 'USDTTRC20' || toCoinFull == 'USDCTRC20') ?
                                        <a href={`https://tronscan.org/#/transaction/${transaction_number_admin}`} target="_blank"><span style={{ fontSize: '0.75rem', fontWeight: '400' }}>{transaction_number_admin}</span></a>
                                        : <span style={{ fontSize: '0.75rem', fontWeight: '400' }}>{(orderStatus == 'completed' || orderStatus == 'hold') ?
                                            transaction_number_admin : ((orderStatus == 'refund') ? ((from_coin == 'BTC') ? <><a href={`https://blockchair.com/bitcoin/transaction/${transaction_number_admin}`} target="_blank"><span style={{ fontSize: '0.75rem', fontWeight: '400' }}>{transaction_number_admin}</span></a></> : ((fromCoinFull == 'USDTERC20' || fromCoinFull == 'USDCERC20' || from_coin == 'ETH') ? <><a href={`https://etherscan.io/tx/${transaction_number_admin}`} target="_blank"><span style={{ fontSize: '0.75rem', fontWeight: '400' }}>{transaction_number_admin}</span></a></> : ((fromCoinFull == 'USDTTRC20' || fromCoinFull == 'USDCTRC20') ? <><a href={`https://tronscan.org/#/transaction/${transaction_number_admin}`} target="_blank"><span style={{ fontSize: '0.75rem', fontWeight: '400' }}>{transaction_number_admin}</span></a></> : transaction_number_admin))) : transaction_number)}</span>))
                            }
                        </Typography>
                    </Grid>

                    <Grid item xs={2} className={classes.paddingLeft15}>
                        <Typography variant="body1">{orderQty}</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.paddingLeft15}>
                        <Typography variant="body1">{orderPrice}</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.paddingLeft15} style={{ paddingRight: '15px', textAlign: 'right' }}>
                        <Typography variant="body1">{orderTotal}</Typography>
                    </Grid>
                </Grid>
                <Grid container style={{ margin: '10px 15px 25px' }}>
                    <Typography variant="body1" style={{ height: '1px', borderBottom: '1px dashed #ccc', width: '98%' }}></Typography>
                </Grid>
            </>
                : <>
                    <Grid container style={{ paddingTop: '20px', marginBottom: '20px' }} id="topinputfield" className={classes.invoiceNumberBody}>
                        <Grid item xs={3} className={classes.paddingLeft15}>
                            <TextInput variant="outlined" disabled source="transaction_id" label="Invoice Number" fullWidth className={classes.inputHeight} />
                        </Grid>
                        <Grid item xs={3} className={classes.paddingLeft15}>
                            <SelectInput variant="outlined" source="status" label="Status" onChange={handleOrderStatus} fullWidth choices={pairtype_choices} />
                        </Grid>
                        <Grid item xs={3} className={classes.paddingLeft15}>
                            <TextInput variant="outlined" disabled source="order_mode_alter" label="Processing Mode" fullWidth className={classes.inputHeight} />
                        </Grid>
                        <Grid item xs={3} className={classes.paddingRight15} style={{ paddingLeft: '15px' }}>
                            <DateInput variant="outlined" source="create_date" label="Create Date" fullWidth />
                        </Grid>
                    </Grid>

                    <Grid container className={classes.marginBottom20} style={{ paddingLeft: '15px' }}>
                        <Typography variant="body1" style={{ color: 'rgb(145, 158, 171)' }}>Details:</Typography>
                    </Grid>

                    <Grid container className={classes.marginBottom20}>
                        <Grid item xs={3} className={classes.paddingLeft15}>
                            <TextInput variant="outlined" source="order_description" disabled label="Description" fullWidth />
                        </Grid>
                        <Grid item xs={3} className={classes.paddingLeft15}>
                            <TextInput variant="outlined" source="transaction_number" disabled label="TrxID" fullWidth />
                        </Grid>
                        <Grid item xs={2} className={classes.paddingLeft15}>
                            <TextInput variant="outlined" source="send_amount" label="Qty" fullWidth />
                        </Grid>
                        <Grid item xs={2} className={classes.paddingLeft15}>
                            <TextInput variant="outlined" source="course_price_alter" label="Price" fullWidth />
                        </Grid>
                        <Grid item xs={2} className={classes.paddingLeft15} style={{ paddingRight: '15px', textAlign: 'right' }}>
                            <TextInput variant="outlined" source="received_amount_alter" label="Total" fullWidth />
                        </Grid>
                    </Grid>

                    <Grid container style={{ margin: '25px 15px' }}>
                        <Typography variant="body1" style={{ height: '1px', borderBottom: '1px dashed #ccc', width: '98%' }}></Typography>
                    </Grid>

                    <Grid container className={classes.marginBottom20} style={{ padding: '0px 16px' }}>
                        <Grid item xs={8}>
                            <Typography variant="body2"></Typography>
                        </Grid>

                        <Grid item xs={4} className={classes.paddingLeft15}>
                            <TextInput variant="outlined" fullWidth source="transaction_number_admin" label="TrxID Admin" />
                        </Grid>
                    </Grid>
                </>}


            {
                (orderStatus != 'refund') ? <>

                    <Grid container style={{ marginBottom: '10px' }}>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ textAlign: 'right' }}>{bonusExchangeText}:</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.paddingRight15}>
                            <Typography variant="body2" style={{ textAlign: 'right' }}>{(bonus > 0) ? '+' : '-'}{bonusExchange} {toCoin}</Typography>
                        </Grid>
                    </Grid>

                    <Grid container style={{ marginBottom: '10px' }}>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ textAlign: 'right', fontWeight: '600' }}>Receivable:</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.paddingRight15}>
                            <Typography variant="body2" style={{ textAlign: 'right', fontWeight: '600' }}>{subTotal} {to_coin}</Typography>
                        </Grid>
                    </Grid>
                </> : ''
            }
            <Grid container style={{ marginBottom: '10px' }}>
                <Grid item xs={10}>
                    <Typography variant="body2" style={{ textAlign: 'right' }}>{feeText}:</Typography>
                </Grid>
                <Grid item xs={2} className={classes.paddingRight15}>
                    <Typography variant="body2" style={{ textAlign: 'right', color: 'red' }}>-{feeAmount} {(orderStatus == 'refund') ? from_coin : to_coin}</Typography>
                </Grid>
            </Grid>

            <Grid container style={{ marginBottom: '10px' }}>
                <Grid item xs={10}>
                    <Typography variant="body2" style={{ textAlign: 'right', fontWeight: '600' }}>Total Receivable:</Typography>
                </Grid>
                <Grid item xs={2} className={classes.paddingRight15}>
                    <Typography variant="body2" style={{ textAlign: 'right', fontWeight: '600' }}>{netTotal} {(orderStatus == 'refund') ? from_coin : to_coin}</Typography>
                </Grid>
            </Grid>
        </SimpleForm >
    )
};

// const OrdersTitle = (props) => {
//     return props.record ? (
//         <OrderField record={props.record} />
//     ) : null;
// };

const OrderDetails = props => {
    return (
        <Edit {...props} actions={<OrderShowActions />}>
            <OrderDetailsForm />
        </Edit>
    )
};
export default OrderDetails;
